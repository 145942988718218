import React, { useEffect, useRef } from 'react';
import { useAuth } from '../../context/AuthContext';

const AuthWrapper = ({ children }) => {
  const { authState, setUser, checkToken } = useAuth();
  const { isLoggedIn, user, loading } = authState;
  const initialCheckDone = useRef(false);

  useEffect(() => {
    const performInitialCheck = async () => {
      if (!initialCheckDone.current) {
        await checkToken();
        if (isLoggedIn) {
          // console.log("User is logged in");
          setUser(user);
        } else {
          // console.log("User is not logged in");
          setUser(null);
        }
        initialCheckDone.current = true;
      }
    };

    performInitialCheck();
  }, [isLoggedIn, user, setUser, checkToken]);

  if (loading) {
    return <div>Lade...</div>; // Or any loading indicator
  }

  return <>{children}</>;
};

export default AuthWrapper;
