// src/pages/Pricing.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext'; // Add this import
import Services from '../components/content/Services';
import { BASE_URL } from '../config';
import Alert from '../components/alerts/Alert'; // Import the Alert component

function PricingCard({ title, subtitle, price, features, onSelect, disabled=false }) {
  return (
    <div className="w-full max-w-sm bg-background-bright-2 dark:bg-background-dark border border-gray-300 dark:border-gray-700 rounded-lg shadow-xl overflow-hidden transition-all duration-300 hover:shadow-xl transform hover:-translate-y-1">
      <div className="px-6 py-8">
        <h3 className="text-2xl font-bold text-gray-900 dark:text-text-dark mb-2">{title}</h3>
        <p className="text-gray-500 dark:text-gray-400 text-sm mb-4">{subtitle} <span className="text-gray-500 dark:text-gray-400 text-sm ml-1">/ Normseite</span></p>
        <div className="flex items-baseline mb-6">
          <span className="text-4xl font-extrabold text-gray-900 dark:text-text-dark">
            {price}
          </span>
        </div>
        <ul className="space-y-3 mb-6">
          {features.map((feature, index) => (
            <li key={index} className="flex items-center text-base">
              <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
              </svg>
              <span className="text-gray-600 dark:text-gray-300">{feature}</span>
            </li>
          ))}
        </ul>
      </div>
      {price !== '0 €' && (
        <div className="px-6 pb-8">
          <button
            onClick={onSelect}
            disabled={disabled}
            className="w-full bg-primary text-white rounded-md shadow-md border-none py-3 font-semibold transition-colors duration-300 hover:bg-primary-dark-1 hover:shadow-none focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Auswählen
          </button>
        </div>
      )}
    </div>
  );
}

function Pricing() {
  const navigate = useNavigate();
  const { authState, checkToken, getAccessToken } = useAuth();
  const [hasActiveSubscription, setHasActiveSubscription] = useState(null);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    const checkSubscription = async () => {
      const activeSubscription = await checkActiveSubscription();
      setHasActiveSubscription(activeSubscription);
      // console.log("activeSubscription:", activeSubscription);
      // console.log("hasActiveSubscription:", hasActiveSubscription);
    };
    checkSubscription();
  }, []);

  const pricingTiers = [
    {
      title: '20 Normseiten',
      price: '50 €',
      subtitle: '2,50 €',
      amount: 5000,
      features: ['Übersetzungen in Leichte Sprache', 'Übersetzungen in Einfache Sprache', 'Glossarfunktion'],
      subscription_tier_id: 1,
      numberOfCharacters: 36000
    },
    {
      title: '50 Normseiten',
      price: '110 €',
      subtitle: '2,20 €',
      amount: 11000,
      features: ['Übersetzungen in Leichte Sprache', 'Übersetzungen in Einfache Sprache', 'Glossarfunktion', 'Angebot zur individuellen Beratung'],
      subscription_tier_id: 2,
      numberOfCharacters: 90000
    },
    {
      title: '100 Normseiten',
      price: '200 €',
      subtitle: '2,00 €',
      amount: 20000,
      features: ['Übersetzungen in Leichte Sprache', 'Übersetzungen in Einfache Sprache', 'Glossarfunktion', 'Angebot zur individuellen Beratung'],
      subscription_tier_id: 3,
      numberOfCharacters: 180000
    },
  ];

  const checkActiveSubscription = async () => {
    const token = await getAccessToken();
    if (!token) {
      return false;
    }

    try {
      const response = await axios.post(
        `${BASE_URL}/payments/has-active-subscription`,
        { },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      console.error("Error checking active subscription:", error);
      return false;
    }
  };

  const handleSelect = (amount, subscription_tier_id, numberOfCharacters) => {
    if (hasActiveSubscription) {
      setShowAlert(true);
      return;
    }

    const isLoggedIn = checkToken();
    if (authState.isLoggedIn && isLoggedIn) {
      navigate(`/revocation?amount=${amount}&subscription_tier_id=${subscription_tier_id}&numberOfCharacters=${numberOfCharacters}`);
    } else {
      const returnUrl = `/revocation?amount=${amount}&subscription_tier_id=${subscription_tier_id}&numberOfCharacters=${numberOfCharacters}`;
      navigate(`/login?returnUrl=${encodeURIComponent(returnUrl)}`);
    }
  };

  return (
    <div className="pricing-container max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      {showAlert && (
        <Alert
          title="Hinweis"
          description="Du hast bereits ein aktives Abonnement."
          onClose={() => setShowAlert(false)}
          variant="info"
        />
      )}
      {/* <Services /> */}
      <h1 className="text-4xl font-semibold text-center mb-12 dark:text-text-dark">Preise</h1>
      <p className="text-xl font-semibold mb-8 text-center dark:text-text-dark">Monatlich kündbare Abonnements.</p>
      <p className="text-xl font-semibold mb-8 text-center dark:text-text-dark">14 Tage Geld-zurück-Garantie.</p>
      
      <div className="pricing-tiers flex flex-wrap justify-center gap-8 my-16">
        {pricingTiers.map((tier, index) => (
          <PricingCard
            key={index}
            title={tier.title}
            subtitle={tier.subtitle}
            price={tier.price}
            features={tier.features}
            onSelect={() => handleSelect(tier.amount, tier.subscription_tier_id, tier.numberOfCharacters)} // Route to the checkout page with the selected amount
            disabled={true}
          />
        ))}
      </div>
      <p className="text-sm mb-8 -mt-4 text-gray-500 dark:text-gray-400">*eine Normseite entspricht bei uns 1.800 Zeichen inklusive Leerzeichen.</p>
      <div className="flex flex-col items-center">
        <p className="text-xl mb-8 dark:text-text-dark">Gemeinnützige Organisationen erhalten bei uns 25 % Rabatt. Bitte kontaktiere uns dafür vorab:</p>
        <a href="mailto:info@leichte-sprache-uebersetzer.de" className="text-xl font-semibold mb-8 dark:text-text-dark">info@leichte-sprache-uebersetzer.de</a>
      </div>
      <Services />
    </div>
    
    
  );
}

export default Pricing;