import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchField from '../components/glossary/SearchField';
import GlossaryList from '../components/glossary/GlossaryList';
import { Helmet } from 'react-helmet';
import { terms } from '../data/glossaryTerms';
import Alert from '../components/alerts/Alert';

import './Glossary.css';

const Glossary = () => {
  const textAreaRef = useRef(null);
  const [glossaryTerm, setGlossaryTerm] = useState('');
  const navigate = useNavigate();
  const [alerts, setAlerts] = useState([]);
  const handleTextChange = (e) => {
    const newText = e.target.value;
    if (newText.length <= 50) {
      setGlossaryTerm(newText);
    }
  };

  const addAlert = (title, description, variant, expireTime = null) => {
    const id = Date.now();
    setAlerts(prevAlerts => [...prevAlerts, { id, title, description, variant, expireTime }]);
  };
  const removeAlert = (id) => {
    setAlerts(prevAlerts => prevAlerts.filter(alert => alert.id !== id));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (glossaryTerm.trim().length >= 2) {
      const encodedTerm = encodeURIComponent(glossaryTerm);
      navigate(`/glossar/${encodedTerm}`);
    } else {
      addAlert('Mindestens 2 Zeichen', 'Bitte geben Sie mindestens 2 Zeichen ein.', 'default');
    }
  };
  
  return (
    <div className="max-w-4xl mx-auto">
      <Helmet>
        <title>Wörterbuch | Leichte Sprache Übersetzer</title>
        <meta name="description" content="Wörterbuch für Leichte Sprache - Erklärungen zu Begriffen und Konzepten." />
        <meta name="keywords" content="Wörterbuch, Leichte Sprache, Erklärungen, Begriffe, Konzepte" />
        <meta property="og:title" content="Wörterbuch für Leichte Sprache" />
        <meta property="og:description" content="Wörterbuch für Leichte Sprache - Erklärungen zu Begriffen und Konzepten." />
        <meta property="og:type" content="article" />
        <meta property="og:image" content="https://www.leichte-sprache-uebersetzer.de/imgs/app/glossar_component_screenshot.webp" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image:alt" content="Illustration related to Leichte Sprache" />
        <meta property="og:site_name" content="Leichte Sprache Übersetzer" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Wörterbuch für Leichte Sprache" />
        <meta name="twitter:description" content="Wörterbuch für Leichte Sprache - Erklärungen zu Begriffen und Konzepten." />
        <meta name="twitter:image" content="https://www.leichte-sprache-uebersetzer.de/imgs/app/glossar_component_screenshot.webp" />
        <meta name="twitter:image:alt" content="Leichte Sprache Übersetzer App" />
        <meta name="twitter:site" content="@YourTwitterHandle" />
        <link rel="canonical" href={window.location.href} />
        <meta httpEquiv="Content-Language" content="de" />
        <meta name="robots" content="index, follow" />
        <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Article",
            "headline": "Wörterbuch für Leichte Sprache",
            "description": "Wörterbuch für Leichte Sprache - Erklärungen zu Begriffen und Konzepten.",
            "image": "https://www.leichte-sprache-uebersetzer.de/imgs/app/glossar_component_screenshot.webp",
            "author": {
              "@type": "Person",
              "name": "Lukas Hecker"
            },
            "publisher": {
              "@type": "Organization",
              "name": "Leichte Sprache Übersetzer",
              "logo": {
                "@type": "ImageObject",
                "url": "https://www.leichte-sprache-uebersetzer.de/favicon.ico"
              }
            },
            "datePublished": "${new Date().toISOString()}",
            "dateModified": "${new Date().toISOString()}"
          }
        `}
        </script>
      </Helmet>
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="">
          <h1 className="text-4xl font-semibold text-center mb-12 dark:text-text-dark">Wörterbuch</h1>
          <p className="text-xl font-semibold mb-8 text-center dark:text-text-dark">für Leichte Sprache</p>
          <p className="text-xl mb-14 text-center dark:text-text-dark">
            Das Wörterbuch für Leichte Sprache erklärt Begriffe und Konzepte - Komplett kostenlos!
          </p>
          <SearchField 
            term={glossaryTerm} 
            handleSubmit={handleSubmit}
            onChange={handleTextChange}
            textAreaRef={textAreaRef}
          />
          <br />
          <br />
          <GlossaryList terms={terms} />
          <br />
          <br />
          <p className='text-sm text-gray-500 dark:text-gray-400'>
            Dieses Glossar wurde mit der Hilfe von <a href="https://hurraki.de/wiki/Hauptseite" className="text-blue-600 dark:text-blue-400 hover:underline" target="_blank" rel="noopener noreferrer">Hurraki</a> erstellt.
          </p>
        </div>
      </div>
      {alerts.map(alert => (
        <Alert
          key={alert.id}
          title={alert.title}
          description={alert.description}
          variant={alert.variant}
          expireTime={alert.expireTime}
          onClose={() => removeAlert(alert.id)}
        />
      ))}
    </div>
  );
};

export default Glossary;
