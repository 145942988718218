import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useTranslation } from 'react-i18next';
import logo from '../assets/logo_inverted_150px.png';
import { useTheme } from '../context/ThemeContext';

const NavbarComponent = () => {
  const { authState, setUser } = useAuth();
  const { user } = authState;
  const { t, i18n } = useTranslation();
  const { isDarkMode, toggleDarkMode } = useTheme();

  const [expanded, setExpanded] = React.useState(false);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [uberUnsDropdown, setUberUnsDropdown] = React.useState(false);

  // Add ref for the mobile menu
  const mobileMenuRef = React.useRef(null);

  // Update the useEffect for handling clicks outside
  React.useEffect(() => {
    const handleClickOutside = (event) => {
      // Get reference to the menu button
      const menuButton = document.querySelector('[aria-controls="mobile-menu"]');
      
      // Only close if click is outside menu AND not on the menu button
      if (mobileMenuRef.current && 
          !mobileMenuRef.current.contains(event.target) && 
          !menuButton.contains(event.target)) {
        setExpanded(false);
      }
    };

    // Add event listener when menu is expanded
    if (expanded) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // Cleanup
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [expanded]);

  const handleLogout = () => {
    setUser(null);
    localStorage.setItem('access_token', '');
    localStorage.setItem('refresh_token', '');
  };

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  // Update handleNavItemClick to always close the menu
  const handleNavItemClick = () => {
    console.log('handleNavItemClick');
    setExpanded(false);
  };

  const toggleLanguage = () => {
    const newLang = i18n.language === 'de' ? 'de-leicht' : 'de';
    i18n.changeLanguage(newLang);
  };

  // Add this function to determine the active language
  const isLeichteSprache = () => i18n.language === 'de-leicht';

  // Update to handle hover events
  const handleUberUnsMouseEnter = () => setUberUnsDropdown(true);
  const handleUberUnsMouseLeave = () => setUberUnsDropdown(false);

  return (
    <nav className="h-16 bg-bright dark:bg-background-dark-2 border-b sticky z-10 top-0 shadow-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <div className="flex-shrink-0 flex items-center">
              <Link to="/" className="flex items-center no-underline" onClick={handleNavItemClick}>
                <img
                  src={logo}
                  width="50"
                  height="50"
                  className="mr-2"
                  alt="Logo"
                />
                <span className="text-black dark:text-text-dark text-md">Übersetzer</span>
              </Link>
            </div>
            <div className="hidden lg:hidden xl:flex xl:space-x-8 mb-1 pl-4">
              <div className="relative ml-2 -mr-4"
                   onMouseEnter={handleUberUnsMouseEnter}
                   onMouseLeave={handleUberUnsMouseLeave}>
                <button
                  className="text-black dark:text-text-dark text-base inline-flex items-center px-1 pt-1 border-b-0 border-transparent leading-5 transition duration-150 ease-in-out no-underline hover:decoration-black dark:hover:decoration-white hover:underline hover:underline-offset-8 bg-transparent"
                  style={{ marginTop: '1rem' }}
                >
                  Über uns
                  <svg className="ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </button>
                <div className={`absolute p-1 z-10 mt-3 w-48 rounded-md shadow-lg bg-background-bright-2 dark:bg-background-dark-2 ring-1 ring-black ring-opacity-5 
                                transition-all duration-200 ease-in-out transform origin-top
                                ${uberUnsDropdown ? 'opacity-100 scale-100' : 'opacity-0 scale-95 pointer-events-none'}`}
                >
                  <div className="py-1 flex flex-col">
                    <NavLinkDropdown to="/uber-uns" onClick={() => setUberUnsDropdown(false)}>Über uns</NavLinkDropdown>
                    <NavLinkDropdown to="/mission" onClick={() => setUberUnsDropdown(false)}>Mission</NavLinkDropdown>
                  </div>
                </div>
              </div>
              <NavLink to="/preise">Preise</NavLink>
              <NavLink to="/api">API</NavLink>
              <NavLink to="/glossar">Wörterbuch</NavLink>
              <NavLink to="/blog">Blog</NavLink>
              
            </div>
          </div>
          <div className={`hidden sm:ml-6 sm:flex sm:items-center`}>
            {/* Language toggle button */}
            <button
              onClick={toggleLanguage}
              className={`mx-4 p-1 bg-bright dark:bg-background-dark-2 border-transparent flex items-center`}
              aria-label={t('toggleLanguage')}
            >
              <span className={`text-sm hidden lg:inline ${isLeichteSprache() ? 'text-black dark:text-text-dark font-bold underline underline-offset-8' : 'text-gray-500 dark:text-gray-400'} hover:underline hover:underline-offset-8`}>
                Leichte Sprache
              </span>
              <img
                src={'/imgs/icons/leichte-sprache-small-free.png'}
                alt="Leichte Sprache"
                className="w-8 h-8 mr-0"
              />
            </button>
            {/* Dark mode toggle button - moved to the end */}
            <button
              onClick={toggleDarkMode}
              className="mx-4 p-1 rounded-full border-none bg-bright dark:bg-background-dark-2"
              aria-label="Toggle dark mode"
            >
              {isDarkMode ? (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-yellow-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
                </svg>
              )}
            </button>
            
            {user ? (
              <div className="ml-3 relative">
                <div>
                  <button
                    type="button"
                    className="inline-flex items-center justify-between w-full px-4 py-2 border border-transparent text-sm font-medium rounded-md text-black dark:text-text-dark no-underline bg-background-bright dark:bg-background-dark-2 hover:underline hover:underline-offset-8"
                    id="user-menu"
                    aria-expanded={dropdownOpen}
                    aria-haspopup="true"
                    onClick={toggleDropdown}
                  >
                    <span className="flex items-center">
                      {(user.identities && user.identities[0]?.identity_data?.display_name) || user.email}
                    </span>
                    <svg className="ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                  </button>
                </div>
                {dropdownOpen && (
                  <div className="origin-top-right absolute border border-gray-300 dark:border-gray-700 right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-background-bright-2 dark:bg-background-dark-2 ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
                    <Link to="/konto" className="block text-black dark:text-text-dark px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-700 no-underline" role="menuitem" onClick={() => setDropdownOpen(false)}>Konto</Link>
                    <button onClick={() => { handleLogout(); setDropdownOpen(false); }} className="block w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 bg-white dark:bg-background-dark border-0" role="menuitem">
                      Logout
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div className="flex space-x-2 px-4">
                <Link
                  to="/login"
                  className="inline-flex items-center px-4 py-2 border border-gray-500 text-sm font-medium rounded-md text-black dark:text-text-dark no-underline bg-white dark:bg-background-dark hover:bg-primary-light-3 dark:hover:bg-primary-dark-3"
                  onClick={handleNavItemClick}
                >
                  Anmelden
                </Link>
                <Link
                  to="/login?tab=register"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white no-underline bg-primary hover:bg-primary-dark-1"
                  onClick={handleNavItemClick}
                >
                  Registrieren
                </Link>
              </div>
            )}
          </div>
          <div className="-mr-2 flex items-center xl:hidden">
            {/* Mobile menu button */}
            <button
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md bg-background-bright dark:bg-background-dark text-text-bright dark:text-text-dark hover:bg-primary-light-3 border border-gray-500 shadow-none"
              aria-controls="mobile-menu"
              aria-expanded="false"
              onClick={() => setExpanded(!expanded)}
            >
              <span className="text-base mr-2">Menü</span>
              <span className="sr-only">Open main menu</span>
              {/* Icon when menu is closed */}
              <svg className={`${expanded ? 'hidden' : 'block'} h-6 w-6`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
              {/* Icon when menu is open */}
              <svg className={`${expanded ? 'block' : 'hidden'} h-6 w-6`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu, show/hide based on menu state. */}
      <div 
        ref={mobileMenuRef}
        className={`bg-background-bright-2 dark:bg-background-dark-2 shadow-lg rounded-lg  ${expanded ? 'block' : 'hidden'} xl:hidden`} 
        id="mobile-menu"
      >
        <div className="pt-2 pb-3 space-y-0">
          <MobileNavLink to="/uber-uns" onClick={handleNavItemClick}>Über uns</MobileNavLink>
          <MobileNavLink to="/mission" onClick={handleNavItemClick}>Mission</MobileNavLink>
          <MobileNavLink to="/preise" onClick={handleNavItemClick}>Preise</MobileNavLink>
          <MobileNavLink to="/api" onClick={handleNavItemClick}>API</MobileNavLink>
          <MobileNavLink to="/glossar" onClick={handleNavItemClick}>Wörterbuch</MobileNavLink>
          <MobileNavLink to="/blog" onClick={handleNavItemClick}>Blog</MobileNavLink>
          {/* Add authentication buttons for mobile */}
          {!user && (
            <div className="flex flex-col space-y-2 px-3 py-2 w-3/4 mx-auto">
              <Link
                to="/login"
                className="w-full text-center px-4 py-2 border border-gray-500 text-sm font-medium rounded-md text-black dark:text-text-dark bg-white dark:bg-background-dark hover:bg-primary-light-3 dark:hover:bg-primary-dark-3 no-underline"
                onClick={handleNavItemClick}
              >
                Anmelden
              </Link>
              <Link
                to="/login?tab=register"
                className="w-full text-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-dark-1 no-underline"
                onClick={handleNavItemClick}
              >
                Registrieren
              </Link>
            </div>
          )}

          <div className="flex justify-between items-center w-full px-4 py-0 text-base font-medium bg-background-bright-2 dark:bg-background-dark-2 border-l-4 border-transparent text-gray-500">
            <button
              onClick={() => {
                toggleDarkMode();
                handleNavItemClick();
              }}
              className="flex items-center p-2 pr-11 -my-6 bg-background-bright-2 dark:bg-background-dark-2 dark:text-text-dark border-none hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              {isDarkMode ? (
                <>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2 text-yellow-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg>
                  Light Mode
                </>
              ) : (
                <>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
                  </svg>
                  Dark Mode
                </>
              )}
            </button>
            <button
              onClick={handleNavItemClick}
              className="p-2 rounded-md border border-gray-500 bg-background-bright-2 hover:bg-primary-light-3 dark:bg-background-dark text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
              aria-label="Close menu"
            >
              <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
}

const NavLink = ({ to, children }) => (
  <Link
    to={to}
    className="text-black dark:text-text-dark text-base inline-flex items-center px-1 pt-1 border-b-0 border-transparent leading-5 transition duration-150 ease-in-out no-underline hover:decoration-black dark:hover:decoration-white hover:underline hover:underline-offset-8"
  >
    {children}
  </Link>
);

const NavLinkDropdown = ({ to, children }) => (
  <Link
    to={to}
    className="text-black dark:text-text-dark text-base rounded-md flex items-center p-2 border-b-0 border-transparent leading-5 transition duration-150 ease-in-out no-underline hover:decoration-black dark:hover:decoration-white hover:bg-gray-100 dark:hover:bg-gray-800 w-full"
  >
    {children}
  </Link>
);

const MobileNavLink = ({ to, children, onClick }) => (
  <Link
    to={to}
    className="block pl-3 pr-4 py-2 no-underline border border-gray-200 dark:border-gray-700 text-base font-medium text-text-bright dark:text-text-dark hover:text-gray-800 dark:hover:text-white dark:hover:bg-gray-700 hover:border-gray-300 transition duration-150 ease-in-out"
    onClick={onClick}
  >
    {children}
  </Link>
);

export default NavbarComponent;
