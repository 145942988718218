// src/components/Feedback.js
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { BASE_URL } from '../../config';
import Alert from '../alerts/Alert'; // Import the Alert component
import Bars from '../loading/Bars'; // Import the Bars component


const Feedback = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    userId: '',
    name: '',
    email: '',
    starRating: 0,
    feedback: ''
  });
  const formRef = useRef();
//   const user = Cookies.get('user')
  const [alert, setAlert] = useState({ isVisible: false, message: '', variant: 'info' });
  const [isLoading, setIsLoading] = useState(false); // Add loading state

  const toggleForm = () => {
    setIsOpen(!isOpen);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRatingChange = (e) => {
    const starRating = parseInt(e.target.value, 10);
    setFormData({ ...formData, starRating });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading to true when submission starts
    const userCookie = Cookies.get('user');
    const user = userCookie ? JSON.parse(userCookie) : null;
    const formRequest = {
        name: formData.name,
        email: formData.email ? formData.email : user ? user.email : '',
        userId: user ? user.id : formData.userId,
        starRating: formData.starRating,
        feedback: formData.feedback
    }
    try {
      await axios.post(`${BASE_URL}/feedback`, formRequest);
      setAlert({ isVisible: true, message: 'Vielen Dank für das Feedback! Empfehlen Sie uns doch gerne weiter.', variant: 'default' });

      setFormData({ name: '', email: '', starRating: 0, feedback: '' });
      setIsOpen(false);
    } catch (error) {
      setAlert({ isVisible: true, message: 'Da hat etwas nicht geklappt. Bitte versuchen Sie es später erneut.', variant: 'default' });
    } finally {
      setIsLoading(false); // Set loading to false after submission completes
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (formRef.current && !formRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div>
      {alert.isVisible && (
        <Alert
          title="Feedback"
          description={alert.message}
          expireTime={4000}
          onClose={() => setAlert({ ...alert, isVisible: false })}
          variant={alert.variant}
        />
      )}
      <div className="fixed bottom-5 right-5">
        {!isOpen && (
        <button
          onClick={toggleForm}
          className="px-4 py-2 bg-background-bright dark:bg-background-dark-2 text-gray-600 dark:text-gray-300 border border-gray-300 dark:border-gray-700 rounded-full shadow-xl hover:bg-gray-200 dark:hover:bg-gray-700"
        >
          Feedback
        </button>
        )}
      </div>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-10">
          <div
            className="absolute inset-0 bg-black opacity-50"
            onClick={() => setIsOpen(false)}
          ></div>
          <div
            ref={formRef}
            className="bg-background-bright dark:bg-background-dark w-3/4 sm:w-1/3 p-6 rounded-md shadow-lg relative"
          >
            <button
              onClick={() => setIsOpen(false)}
              className="absolute top-2 right-2 bg-background-bright dark:bg-background-dark border-none text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-700 rounded-md"
            >
              &times;
            </button>
            <form onSubmit={handleSubmit} className="space-y-4 relative">
              {isLoading && (
                <div className="absolute inset-0 flex items-center justify-center bg-background-bright dark:bg-background-dark bg-opacity-75 z-20">
                  <Bars />
                </div>
              )}
              <h1 className='text-center text-2xl font-bold dark:text-text-dark'>Feedback</h1>
              <p className='text-center text-sm text-gray-500 dark:text-gray-400'>Ihr Feedback hilft uns den Leichte Sprache Übersetzer zu verbessern.</p>
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Name:
                </label>
                <input
                  type="text"
                  name="name"
                  placeholder="optional"
                  value={formData.name}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 dark:border-gray-700 rounded-md shadow-sm py-2 px-3 bg-background-bright-2 dark:bg-background-dark-2 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:placeholder:text-gray-400"
                />
              </div>
              <div>
                <label className="block text-sm font-medium  text-gray-700 dark:text-gray-300">
                  E-Mail:
                </label>
                <input
                  type="email"
                  name="email"
                  placeholder='optional'
                  value={formData.email}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 dark:border-gray-700 rounded-md shadow-sm py-2 px-3 bg-background-bright-2 dark:bg-background-dark-2 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:placeholder:text-gray-400"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Bewertung der App: <span className="text-red-500">*</span>
                </label>
                <div className="flex space-x-1">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <label key={star}>
                      <input
                        type="radio"
                        name="starRating"
                        value={star}
                        checked={formData.starRating === star}
                        onChange={handleRatingChange}
                        className="hidden"
                        required
                      />
                      <svg
                        className={`w-6 h-6 cursor-pointer ${
                          formData.starRating >= star ? 'text-yellow-500' : 'text-gray-300 hover:text-yellow-500'
                        }`}
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.286 3.97a1 1 0 00.95.69h4.18c.969 0 1.371 1.24.588 1.81l-3.39 2.46a1 1 0 00-.364 1.118l1.286 3.97c.3.921-.755 1.688-1.54 1.118l-3.39-2.46a1 1 0 00-1.175 0l-3.39 2.46c-.784.57-1.838-.197-1.54-1.118l1.286-3.97a1 1 0 00-.364-1.118L2.34 9.397c-.783-.57-.38-1.81.588-1.81h4.18a1 1 0 00.95-.69l1.286-3.97z" />
                      </svg>
                    </label>
                  ))}
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Feedback:
                </label>
                <textarea
                  name="feedback"
                  value={formData.feedback}
                  placeholder="Was fanden Sie gut? Was können wir verbessern?"
                  onChange={handleChange}
                  rows={5}
                  required
                  className="mt-1 block w-full border border-gray-300 dark:border-gray-700 rounded-md shadow-sm py-2 px-3 bg-background-bright-2 dark:bg-background-dark-2 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:placeholder:text-gray-400"
                />
              </div>
              <div className="flex justify-center">
                <button
                  type="submit"
                  className="w-1/2 flex justify-center py-2 px-4 border border-gray-400 dark:border-gray-600 rounded-md shadow-md text-sm font-medium text-black dark:text-text-dark bg-background-bright-2 dark:bg-background-dark hover:shadow-lg dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Abschicken
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Feedback;
