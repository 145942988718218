import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const ProfileCard = ({ name, role, imageUrl, description }) => (
  <div className="bg-background-bright-2 dark:bg-background-dark rounded-lg shadow-md border border-gray-200 dark:border-gray-700 p-4 sm:p-6 flex flex-col items-center transition-all duration-300 ease-in-out hover:shadow-xl hover:scale-105">
    <img src={imageUrl} alt={`Bild von ${name}`} className="w-full max-w-[16rem] h-auto aspect-square rounded-lg mb-4 object-cover" width="256" height="256"/>
    <h2 className="text-xl sm:text-2xl font-bold mb-2 dark:text-text-dark">{name}</h2>
    <p className="text-base sm:text-lg text-gray-600 dark:text-gray-400 mb-4">{role}</p>
    <p className="text-left text-sm sm:text-base dark:text-gray-300" dangerouslySetInnerHTML={{ __html: description }}></p>
  </div>
);

const UberUns = () => {
  const { t } = useTranslation();

  return (
    <div className="max-w-4xl mx-auto px-4 py-8 text-lg">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('aboutUs.title')} | Leichte Sprache Übersetzer</title>
        <meta name="description" content={`${t('aboutUs.title')} - Leichte Sprache Übersetzer`} />
        <meta name="keywords" content={`${t('aboutUs.title')}, Leichte Sprache Übersetzer, Einfach erklärt, Definition`} />
        <meta property="og:title" content={`${t('aboutUs.title')} - Leichte Sprache Übersetzer`} />
        <meta property="og:description" content={`${t('aboutUs.title')} - Leichte Sprache Übersetzer`} />
        <meta property="og:type" content="article" />
        <meta property="og:image" content="https://www.leichte-sprache-uebersetzer.de/imgs/app/glossar_component_screenshot.webp" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image:alt" content="Illustration related to Leichte Sprache" />
        <meta property="og:site_name" content="Leichte Sprache Übersetzer" />
      </Helmet>
      <article className="prose lg:prose-xl">
        <div className="flex flex-col items-center justify-center mb-12">
          <h1 className="text-4xl font-semibold mb-6">{t('aboutUs.title')}</h1>
          <p className="text-md mb-2">{t('aboutUs.subtitle1')}</p>
          <p className="text-md mb-2">{t('aboutUs.subtitle2')}</p>
          <p className="text-md mb-2">{t('aboutUs.subtitle3')}</p>
          <p className="text-md mb-8">{t('aboutUs.subtitle4')}</p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 my-12">
          <ProfileCard
            name={t('aboutUs.lukas.name')}
            role={t('aboutUs.lukas.role')}
            imageUrl="imgs/other/lukas-foto.webp"
            description={t('aboutUs.lukas.description')}
          />
          <ProfileCard
            name={t('aboutUs.pascal.name')}
            role={t('aboutUs.pascal.role')}
            imageUrl="imgs/other/pascal-foto.webp"
            description={t('aboutUs.pascal.description')}
          />
        </div>
        <p dangerouslySetInnerHTML={{ __html: t('aboutUs.paragraph1') }}></p>
        <p dangerouslySetInnerHTML={{ __html: t('aboutUs.paragraph2') }}></p>
        <p dangerouslySetInnerHTML={{ __html: t('aboutUs.paragraph3') }}></p>
        <p>{t('aboutUs.contactUs')} <a href="mailto:info@leichte-sprache-uebersetzer.de"> per E-Mail</a>.</p>
      </article>
    </div>
  );
};

export default UberUns;
